import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import './i18n';
import { v4 as uuidv4 } from 'uuid';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { TemplatesContextProvider } from './contexts/TemplatesContext';

import './styles/styles-core.scss';
import { queryClient } from './services/queryClient';
import { ToastProvider } from './contexts/ToastContext';
import { initGA } from './utils/analytics';
import { initClarity } from './utils/clarityMS';

if (process.env.REACT_APP_TYPE_ENV === 'production') {
  initClarity();
  initGA();
}

export const tabId = uuidv4();
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <ToastProvider>
          <AuthProvider>
            <TemplatesContextProvider>
              <App />
            </TemplatesContextProvider>
          </AuthProvider>
        </ToastProvider>
      </QueryClientProvider>
    </Router>
  </React.StrictMode>,

  document.getElementById('root')
);
