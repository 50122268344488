import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { Col, Grid, Row } from 'inmaster-ui';
import { MdAdd, MdSentimentNeutral } from 'react-icons/md';
import { Button, ButtonTextOutside } from 'ui-components/Button';
import styles from './EmptyMessage.module.css';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  subtitle: string | JSX.Element;
  id: string;
  buttonText?: string;
  disabled?: boolean;
  action?: () => unknown;
  height?: string;
  width?: string;
  minHeight?: string;
}
const EmptyMessage = ({
  title,
  subtitle,
  id,
  buttonText,
  disabled,
  action,
  height,
  minHeight,
  width,
  ...props
}: IProps) => {
  return (
    <Grid fluid>
      <Row {...props} style={{ height, minHeight, width }}>
        <Col
          xs={12}
          className={classNames([
            styles.bodyMessage,
            height ? null : styles.fullPage
          ])}>
          <div
            className={classNames([
              'd-flex justify-center',
              !width && styles.content
            ])}>
            <div
              className={classNames([
                'd-flex justify-center align-center',
                styles.ball
              ])}>
              <span
                className={classNames([
                  'd-flex justify-center',
                  styles.iconInBall
                ])}>
                <MdSentimentNeutral size={33} />
              </span>
            </div>

            <div
              className={classNames(['d-flex flex-column align-start ml-4'])}>
              <h1 className="title-xl-lg">{title}</h1>
              <span className={classNames(['mt-3'])}>{subtitle}</span>
              {buttonText && (
                <ButtonTextOutside
                  value={buttonText}
                  className="mt-3 text-uppercase">
                  <Button
                    disabled={disabled}
                    id={`button-${id}`}
                    rounded
                    onClick={() => action && action()}>
                    <MdAdd size={22} />
                  </Button>
                </ButtonTextOutside>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

EmptyMessage.defaultProps = {
  action: null,
  disabled: false,
  buttonText: undefined,
  height: null,
  minHeight: null,
  width: null
};

export { EmptyMessage };
