import classNames from 'classnames';
import React, { useRef } from 'react';
import { MdClose } from 'react-icons/md';
import { ButtonIcon } from 'ui-components/Button';
import { CSSTransition } from 'react-transition-group';
import styles from './Drawer.module.css';

const Drawer = ({
  width = '35vw',
  closable = true,
  title = '',
  show = false,
  onClose = () => {},
  children,
  responsiveWidth = {},
  ...props
}) => {
  const drawerRef = useRef(null);
  const overlayDrawerRef = useRef(null);

  function generateResponsiveWidth() {
    let styleResponsive = {};
    let keys = ['xs', 'sm', 'md', 'lg', 'xl'];
    for (const key of keys) {
      if (key in responsiveWidth) {
        styleResponsive[`--drawer-width-${key}`] = responsiveWidth[key];
        continue;
      }
      styleResponsive[`--drawer-width-${key}`] = width;
    }
    return styleResponsive;
  }
  return (
    <CSSTransition
      in={show}
      timeout={500}
      classNames={{
        enterActive: styles.overlayDrawerEnterActive,
        enterDone: styles.overlayDrawerEnterDone,
        exitActive: styles.overlayDrawerEnterExitActive,
        exitDone: styles.overlayDrawerExitDone
      }}
      nodeRef={overlayDrawerRef}>
      <div
        ref={overlayDrawerRef}
        className={classNames([
          styles.overlayDrawer,
          show ? null : styles.overlayDrawerHidden
        ])}>
        <CSSTransition
          in={show}
          timeout={500}
          classNames={{
            enterActive: styles.containerDrawerEnterActive,
            enterDone: styles.containerDrawerEnterDone,
            exitActive: styles.containerDrawerExitActive,
            exitDone: styles.containerDrawerExitDone
          }}
          nodeRef={drawerRef}>
          <>
            {show && (
              <div
                className={styles.closebleArea}
                onClick={() => {
                  onClose();
                }}
              />
            )}
            <div
              ref={drawerRef}
              className={classNames([
                styles.containerDrawer,
                show ? styles.active : styles.hidden
              ])}
              style={Object.assign(
                { '--drawer-width': width ? width : '35vw' },
                generateResponsiveWidth()
              )}
              {...props}>
              <div className={styles.topDrawer}>
                <h1 className={styles.titleDrawer}>{title}</h1>
                {closable ? (
                  <ButtonIcon
                    id="btn-close-drawer"
                    onClick={() => {
                      onClose();
                    }}>
                    <MdClose size={18} />
                  </ButtonIcon>
                ) : null}
              </div>
              <div className={styles.contentDrawer}>{children}</div>
            </div>
          </>
        </CSSTransition>
      </div>
    </CSSTransition>
  );
};

export default Drawer;
